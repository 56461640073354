const $clone = require('lodash.clone')
const $merge = require('lodash.merge')
const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

const configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}
const findTag = (slug, tags) => {
  return tags.find((tag) => tag.slug === slug)
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5de522a38c8aa12990c5bce1',
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    metadatas: {
      name: 'Radio M',
      colorPrimary: '#051D54',
      supportEmailAddress: 'support@bubblecast.co',
    },
    authMode: ['providers'],
    analytics: {
      matomo: '14',
    },
    nuxt: {
      head: {
        title: 'Radio M',
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Retrouvez vos podcasts sur Radio M`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'noindex,nofollow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content: 'Macif',
          },
          {
            hid: 'ogTitle',
            name: 'og:title',
            content: 'Radio M',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content: `Retrouvez vos podcasts sur Radio M`,
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
    useLTS: true,
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            type: 'tag',
            tagId: '5f4f6d760fc1bc58476fb233',
            i18n: {
              fr: {
                title: 'Émissions des dirigeants',
              },
            },
            heading: {
              color: '#051D54',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
          },
          {
            type: 'tag',
            tagId: '664f384c83390ceeafa41f6a',
            i18n: {
              fr: {
                title: 'Allo Diffuz',
              },
            },
            heading: {
              color: '#FCDD2B',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: false,
            },
          },
          {
            type: 'tag',
            tagId: '5f4f6d610fc1bc43c36fb231',
            i18n: {
              fr: {
                title: 'Destinations solidaires',
              },
            },
            heading: {
              color: '#cbdb16',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: false,
            },
          },
          {
            type: 'tag',
            tagId: '5f4f6d6e0fc1bca9296fb232',
            i18n: {
              fr: {
                title: '60 ans',
              },
            },
            heading: {
              color: '#051D54',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: false,
            },
          },
          {
            type: 'tag',
            tagId: '670505346951b670e37e67f1',
            i18n: {
              fr: {
                title: 'Autres contenus',
                description: `Autres podcasts de la macif`,
              },
            },
            heading: {
              color: '#F5816A',
              canRadio: false,
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
            },
            filters: {
              type: 'solo',
              soloTagId: '6704e94f3ececc0d48dff3d4',
              sort: (category) => {
                // seulement et dans cet ordre :
                // Cancers, Maladies chroniques, Maladies rares, Autres
                // 1) on clone les tags
                const clonedCategory = $clone(category)
                const subTags = clonedCategory.tags
                const displaidTags = []
                displaidTags.push(findTag('voix-macif', subTags))
                displaidTags.push(findTag("c'est-vous-ma-preference", subTags))
                displaidTags.push(
                  findTag("chaine-de-l'engagement-m-le-mag", subTags)
                )
                displaidTags.push(findTag('m-le-mag', subTags))

                clonedCategory.tags = displaidTags.filter(Boolean)
                return clonedCategory
              },
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
