/**
 * configuration applied when BROCOLI_API_MODE = staging
 */
const $clone = require('lodash.clone')
const findTag = (slug, tags) => {
  return tags.find((tag) => tag.slug === slug)
}
module.exports = {
  useShell: [
    'bb-default',
    {
      mode: 'universes',
      universes: [
        {
          type: 'tag',
          tagId: '67d000c5aa78d500ef07e7f7',
          i18n: {
            fr: {
              title: 'Emissions spéciales',
            },
          },
          heading: {
            color: '#00C9F6',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '5f4f6d760fc1bc58476fb233',
          i18n: {
            fr: {
              title: 'Émissions des dirigeants',
            },
          },
          heading: {
            color: '#051D54',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
        },
        {
          type: 'tag',
          tagId: '664f384c83390ceeafa41f6a',
          i18n: {
            fr: {
              title: 'Allo Diffuz',
            },
          },
          heading: {
            color: '#FCDD2B',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: false,
          },
        },
        {
          type: 'tag',
          tagId: '5f4f6d610fc1bc43c36fb231',
          i18n: {
            fr: {
              title: 'Destinations solidaires',
            },
          },
          heading: {
            color: '#cbdb16',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: false,
          },
        },
        {
          type: 'tag',
          tagId: '5f4f6d6e0fc1bca9296fb232',
          i18n: {
            fr: {
              title: '60 ans',
            },
          },
          heading: {
            color: '#051D54',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: false,
          },
        },
        {
          type: 'tag',
          tagId: '66deef95f04a4f436649f637',
          i18n: {
            fr: {
              title: 'Autres contenus',
              description: `Autres podcasts de la macif`,
            },
          },
          heading: {
            color: '#F5816A',
            canRadio: false,
            dark: true,
            buttonColor: 'white',
            buttonLight: true,
          },
          filters: {
            type: 'solo',
            soloTagId: '66def095f04a4f822c49f63c',
            sort: (category) => {
              // seulement et dans cet ordre :
              // Cancers, Maladies chroniques, Maladies rares, Autres
              // 1) on clone les tags
              const clonedCategory = $clone(category)
              const subTags = clonedCategory.tags
              const displaidTags = []
              displaidTags.push(findTag('voix-macif', subTags))
              displaidTags.push(findTag("c'est-vous-ma-preference", subTags))
              displaidTags.push(
                findTag("chaine-de-l'engagement-m-le-mag", subTags)
              )
              displaidTags.push(findTag('m-le-mag', subTags))

              clonedCategory.tags = displaidTags.filter(Boolean)
              return clonedCategory
            },
          },
        },
      ],
    },
  ],

  authMode: ['providers'],
  authOAuthProviders: [
    [
      'WSSOKeycloak',
      {
        realm: 'Macif',
        clientId: 'macif-saml',
        buttonText: 'Connexion Macif',
      },
    ],
  ],
}
